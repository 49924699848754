<template>
  <div class="saleSendDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.sale.id ? '查看' : '新增' }}销售明细（发车）</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
        </el-button-group>
        <el-text type="danger" v-if="data.sale.receiveDate"> * 本车已到达，请在销售明细（到达）中修改</el-text>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="发货日期">
            <el-date-picker style="width: 100%;" v-model="data.sale.sendDate" type="date" @change="handleChangeSendDate" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储">
            <el-select v-model="data.sale.storage" filterable>
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车号">
            <el-autocomplete style="width: 100%;" v-model="data.sale.truckNumber" :fetch-suggestions="getTruckNumbers" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发货重车数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantityGross" @change="computeSendQuantity(); computeSaleMix();" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发货皮重数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantityTare" @change="computeSendQuantity(); computeSaleMix();" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="涨吨数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendExpandedQuantity" @change="computeSendQuantity(); computeSaleMix();" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出库数量">
            <el-input-number disabled style="width: 100%;" :controls="false" :precision="2" v-model="data.sale.sendQuantity" @change="computeSaleMix" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注信息">
            <el-input v-model="data.sale.note" :max="100" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  customList: [],
  storageList: [],
  productList: [],
  transportCompanyList: [],
  customNoteList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "saleSendDetail")
const data = reactive({
  sale: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { saleMixDetailList: [] }
})
data.sale.saleMixDetailList.push({})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

const handleChangeSendDate = () => {
  // 重新查询配比
  show.customNoteList = []
  if (data.sale.sendDate) {
    const useDate = util.parseTime(data.sale.sendDate, '{y}-{m}-{d}')
    api.get('/backend/customNoteToMix/all', { params: { useDate } }).then(res => {
      show.customNoteList = res.list
    })
  }
  // 清除已选择的配比
  data.sale.customNote = null
  data.sale.saleMixDetailList = [{}]
}

const delRow = (row) => {
  data.sale.saleMixDetailList = data.sale.saleMixDetailList.filter(item => item.product != row.product)
  computeSaleMix()
}

const checkRowProduct = (row) => {
  const l1 = data.sale.saleMixDetailList.length
  const l2 = data.sale.saleMixDetailList.filter(item => item.product != row.product).length
  if (l1 != l2 + 1) {
    ElMessage.error("煤矿品名重复")
    row.product = null
  } else {
    data.sale.saleMixDetailList.push({})
  }
}

const send = () => {
  let sumSendQuantityMix = 0
  data.sale.saleMixDetailList.forEach(mixDetail => {
    if (mixDetail.sendQuantity && mixDetail.product) {
      sumSendQuantityMix = math.plus(sumSendQuantityMix, mixDetail.sendQuantity)
    }
  })
  if (sumSendQuantityMix != data.sale.sendQuantity) {
    ElMessage.error("配比数量不等于出库数量，请重新填写配比数量")
    return
  }
  const param = { id: data.sale.id }
  param.sendDate = util.parseTime(data.sale.sendDate, '{y}-{m}-{d}')
  param.saleMixDetailList = data.sale.saleMixDetailList.filter(item => item.product && item.sendQuantity).map(item => {
    return {
      product: item.product,
      mixNumber: item.mixNumber,
      sendQuantity: item.sendQuantity,
    }
  })
  param.storage = data.sale.storage
  param.custom = data.sale.custom
  param.transportCompany = data.sale.transportCompany
  param.truckNumber = data.sale.truckNumber
  param.sendQuantityGross = data.sale.sendQuantityGross
  param.sendQuantityTare = data.sale.sendQuantityTare
  param.sendQuantityInitial = data.sale.sendQuantity
  param.sendQuantity = data.sale.sendQuantity
  param.sendExpandedQuantity = data.sale.sendExpandedQuantity
  param.transportPrice = data.sale.transportPrice
  param.customNote = data.sale.customNote
  param.note = data.sale.note
  if (!param.sendQuantityGross) {
    param.sendQuantityGross = 0
  }
  if (!param.sendQuantityTare) {
    param.sendQuantityTare = 0
  }
  if (!param.sendExpandedQuantity) {
    param.sendExpandedQuantity = 0
  }
  api.post('/backend/sale/send', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

// const filterCustomNoteList = () => {
//   return show.customNoteList.filter(c => {
//     return c.customNote.indexOf(data.sale.customNote) >= 0
//   })
// }

// const handleSelect = (customNote) => {
//   data.sale.storage = customNote.storage
//   data.sale.saleMixDetailList = customNote.mixList
//   data.sale.saleMixDetailList.push({})
//   computeSaleMix()
// }

const handleCustomNoteSelect = (customNoteString) => {
  const customNote = show.customNoteList.filter(x => x.customNote == customNoteString)[0]
  data.sale.storage = customNote.storage
  data.sale.saleMixDetailList = customNote.mixList
  data.sale.saleMixDetailList.push({})
  computeSaleMix()
}

const computeSendQuantity = () => {
  let sendQuantityGross = data.sale.sendQuantityGross
  let sendQuantityTare = data.sale.sendQuantityTare
  let sendExpandedQuantity = data.sale.sendExpandedQuantity
  if (!sendQuantityGross) {
    sendQuantityGross = 0
  }
  if (!sendQuantityTare) {
    sendQuantityTare = 0
  }
  if (!sendExpandedQuantity) {
    sendExpandedQuantity = 0
  }
  data.sale.sendQuantity = math.plus(sendQuantityGross, -sendQuantityTare, -sendExpandedQuantity)
}

const computeSaleMix = () => {
  if (data.sale.saleMixDetailList == null) {
    return
  }
  const saleMixDetailList = data.sale.saleMixDetailList.filter(x => x.product)
  if (data.sale.sendQuantity && saleMixDetailList.length > 0) {
    let mixNumber = 0
    for (const i in saleMixDetailList) {
      const saleMixDetailItem = saleMixDetailList[i]
      mixNumber = math.plus(mixNumber, saleMixDetailItem.mixNumber || 0)
    }
    if (mixNumber > 0) {
      let sumQuantity = 0
      for (const i in saleMixDetailList) {
        const saleMixDetailItem = saleMixDetailList[i]
        if (!saleMixDetailItem.mixNumber) {
          saleMixDetailItem.sendQuantity = 0
        } else {
          if (i < saleMixDetailList.length - 1) {
            saleMixDetailItem.sendQuantity = math.halfUp(data.sale.sendQuantity * saleMixDetailItem.mixNumber / mixNumber, 2)
          } else {
            saleMixDetailItem.sendQuantity = math.plus(data.sale.sendQuantity, -sumQuantity)
          }
          sumQuantity = math.plus(sumQuantity, saleMixDetailItem.sendQuantity)
        }
      }
      ElMessage.success('已自动配比数量')
    }
  }
}

const changeMixQuantity = () => {
  for (const mixIndex in data.sale.saleMixDetailList) {
    const mixDetail = data.sale.saleMixDetailList[mixIndex]
    if (mixDetail.sendQuantity && mixDetail.product) {
      mixDetail.mixNumber = mixDetail.sendQuantity
    }
  }
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/sale/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}

// 关闭页面生命周期
onUnmounted(() => {
  if (!data.sale.id) {
    if (JSON.stringify(data.sale) == '{}') {
      cache.setObject(cache.keys.pageCache + "saleSendDetail", null)
    } else {
      cache.setObject(cache.keys.pageCache + "saleSendDetail", data.sale)
    }
  }
})
if (!data.sale.id) {
  const sale = cache.getObject(cache.keys.pageCache + "saleSendDetail")
  if (sale) {
    data.sale = sale
    ElMessage.success('已快捷填写上次填写的信息')
  }
}
</script>

<style lang="less">
.saleSendDetail {
  margin: auto;
  max-width: 900px;
}
</style>